import { css } from '@emotion/react'
import React, { memo } from 'react'
import { useIndicatorStore } from '~/store/useIndicatorStore'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import styled from '@emotion/styled'
import { useThemeStore } from '~/components/theme/useThemeStore'

/**
 * 使用注意事項:
 *
 * ## 此元件數值是在指標內使用useIndicatorStore傳參至此
 * 必放state項目有:
 * symbolNumber => 商品代碼
 * entryPrice => 進出場價位
 * entryTime => 進出場時間
 * nowClose => 現價
 * target1 => 第一停利目標
 *
 * 選放state項目有: targetType => 停利類型:百分比/點數 target2 => 若有第二個停利值再使用:可參考good178_stock內指標 targetFlag =>
 * 若有第二個停利值再使用:可參考good178_stock內指標
 *
 * 指標進場與出場必須都要更新相關參數,才能確保數值正確
 */
export const HoldingCostPrice = memo(function HoldingCostPrice(props: {
  tragetCount?: number
  floatingPoint?: number
}) {
  /** 停利點浮點數 */
  const floatingPointNumber = props.floatingPoint ?? 0
  /** 主題顏色 */
  const theme = useThemeStore(s => s.theme)
  const isDark = theme === 'dark' ? true : false ?? true
  /** 背景色 */
  const backgroundColor = isDark ? '#333333' : '#eeeeee'
  /** 文字顏色 */
  const textColor = isDark ? '#eeeeee' : '#222222'
  /** 對比文字顏色 */
  const textColorContrast = isDark ? '#222222' : '#eeeeee'
  /** 顯示1個或2個停利 */
  const numberOfTraget = props.tragetCount ?? 2
  /** 進場價 */
  const entryPrice = useIndicatorStore(state => state.entryPrice) || 0
  /** 進場時間 */
  const entryUnixTime = useIndicatorStore(state => state.entryTime) || 0
  /** 現在收盤價 */
  const nowClose = useIndicatorStore(state => state.nowClose) || 0
  /** 停利類型 */
  const targetType = useIndicatorStore(state => state.targetType) || 'percent'
  /** 目標1價格 */
  const target1Point = useIndicatorStore(state => state.target1) || 0
  /** 目標2價格 */
  const target2Point = useIndicatorStore(state => state.target2) || 0
  /** 商品代碼 */
  const _symbolNumber = useIndicatorStore(state => state.symbolNumber) || ''
  /** 多停利階段 0~4 */
  const tragetFlag = useIndicatorStore(state => state.targetFlag) || 0
  /** 進場|出場日期字串 */
  const entryDate = entryUnixTime === 0 ? '---' : new Date(entryUnixTime).toLocaleDateString()
  /** 進場|出場時間字串 */
  const entryTime =
    entryUnixTime === 0
      ? '---'
      : new Date(entryUnixTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

  /** 多空單判別 */
  const isShortHolding = typeof entryPrice === 'number' && entryPrice < 0 ? true : false

  /** 多空單String */
  const marketPostion = entryPrice === 0 ? '無部位' : isShortHolding ? '-1' : '+1'

  /** 部位方向 */
  const marketPostionEntryPrice =
    entryPrice === 0 ? 0 : entryPrice < 0 ? entryPrice * -1 : entryPrice

  /** 未平倉浮動點數(index) */
  const openPosition =
    nowClose !== 0 ? (isShortHolding ? (nowClose + entryPrice) * -1 : nowClose - entryPrice) : 0

  /** 未平倉浮動%數(stock) */
  const percentOpenPosition =
    nowClose !== 0
      ? isShortHolding
        ? (1 + nowClose / entryPrice) * 1 * 100
        : (nowClose / entryPrice - 1) * 100
      : 1

  /** 空單停利點1 */
  const shortTarget1 =
    targetType === 'percent'
      ? marketPostionEntryPrice * (1 - target1Point)
      : marketPostionEntryPrice - target1Point

  /** 多單停利點1 */
  const longTarget1 =
    targetType === 'percent'
      ? marketPostionEntryPrice * (1 + target1Point)
      : marketPostionEntryPrice + target1Point

  /** 空單停利點2 */
  const shortTarget2 =
    targetType === 'percent'
      ? marketPostionEntryPrice * (1 - target2Point)
      : marketPostionEntryPrice - target2Point

  /** 多單停利點2 */
  const longTarget2 =
    targetType === 'percent'
      ? marketPostionEntryPrice * (1 + target2Point)
      : marketPostionEntryPrice + target2Point

  /** 停利數值1 */
  const target1 =
    entryPrice === 0
      ? '---'
      : isShortHolding
      ? shortTarget1.toFixed(floatingPointNumber)
      : longTarget1.toFixed(floatingPointNumber)

  /** 停利數值2 */
  const target2 =
    entryPrice === 0
      ? '---'
      : isShortHolding
      ? shortTarget2.toFixed(floatingPointNumber)
      : longTarget2.toFixed(floatingPointNumber)

  /** 停利背景顏色 */
  const targetBgColor = (flag: number) => {
    return tragetFlag >= flag && entryPrice !== 0 ? '#aa9900' : backgroundColor
  }

  /** 停利文字顏色 */
  const targetTextColor = (flag: number) => {
    return tragetFlag >= flag && entryPrice !== 0 ? '#ffffff' : textColor
  }

  /** 商品類別 股票類 與 指數類 */
  const symbolType =
    _symbolNumber.includes('-') ||
    _symbolNumber.includes('TSEA') ||
    _symbolNumber.includes('TSE') ||
    _symbolNumber.includes('TWS') ||
    _symbolNumber.includes('OTC')
      ? 'index'
      : 'stock'

  /** 股票獲利&數 */
  const stockTarget = (
    <div>
      {nowClose !== 0 ? (percentOpenPosition < 0 ? '' : '+') : ''}
      {percentOpenPosition.toFixed(2)}%
    </div>
  )

  /** 指數獲利點數 */
  const indexTarget = (
    <div>
      {nowClose !== 0 ? (percentOpenPosition < 0 ? '' : '+') : ''}
      {openPosition.toFixed(0)}
    </div>
  )

  /** 顯示 進場|出場 字眼 */
  const TextState = entryPrice === 0 ? '出' : '進'
  /** 獲利格式 */
  const textTarget = symbolType === 'index' ? '獲利點數' : '獲利%數'
  /** 獲利數值 */
  const valueTarget = entryPrice === 0 ? '---' : symbolType === 'index' ? indexTarget : stockTarget
  /** 獲利字體顏色 */
  const valueTargetColor = entryPrice !== 0 ? (openPosition < 0 ? '#33aa33' : '#ff0000') : textColor
  /** 進場成本背景顏色 */
  const infoMpBgColor = entryPrice === 0 ? backgroundColor : isShortHolding ? '#33aa33' : '#ee0000'
  /** 進場成本文字顏色 */
  const infoMpColor = entryPrice === 0 ? textColor : '#ffffff'

  return (
    <div
      css={css`
        ${flex.v.allCenter};
        height: 100%;
        width: 100%;
        color: ${textColor};
      `}
    >
      <InfoTitle>
        <InfoItemText>{TextState}場日期 :</InfoItemText>
        <InfoText backgroundColor={backgroundColor}>{entryDate}</InfoText>
      </InfoTitle>
      <InfoTitle>
        <InfoItemText>{TextState}場時間 :</InfoItemText>
        <InfoText backgroundColor={backgroundColor}>{entryTime}</InfoText>
      </InfoTitle>
      <InfoTitle>
        <InfoItemText>進場成本 :</InfoItemText>
        <InfoMpText
          backgroundColor={infoMpBgColor}
          textColor={infoMpColor}
        >
          {marketPostion} @ {marketPostionEntryPrice}
        </InfoMpText>
      </InfoTitle>
      <InfoTitle>
        <InfoItemText>{textTarget} :</InfoItemText>
        <InfoText
          backgroundColor={backgroundColor}
          textColor={valueTargetColor}
        >
          {valueTarget}
        </InfoText>
      </InfoTitle>
      <div
        css={css`
          ${flex.v.default};
          height: 100%;
          width: 100%;
        `}
      >
        {numberOfTraget >= 1 && (
          <InfoTitle>
            <InfoItemText>停利點{numberOfTraget >= 2 ? '1' : ''}:</InfoItemText>
            <InfoText
              backgroundColor={targetBgColor(1)}
              textColor={targetTextColor(1)}
            >
              {target1}
            </InfoText>
          </InfoTitle>
        )}
        {numberOfTraget >= 2 && (
          <InfoTitle>
            <InfoItemText>停利點2:</InfoItemText>
            <InfoText
              backgroundColor={targetBgColor(2)}
              textColor={targetTextColor(2)}
            >
              {target2}
            </InfoText>
          </InfoTitle>
        )}
      </div>
    </div>
  )
})

type BasicProps = {
  textColor?: string
  backgroundColor?: string
}

export const InfoTitle = styled.div<BasicProps>`
  ${flex.h.default};
  justify-content: left;
  align-items: left;
  padding: 10px 0px 0px 10px;
  width: 100%;
  font-size: 18px;
`
export const InfoItemText = styled.div<BasicProps>`
  ${flex.h.default};
  width: 35%;
  font-size: 18px;
`

export const InfoText = styled.div<BasicProps>`
  ${flex.h.allCenter};
  width: 65%;
  margin: 0px 20px 0px 10px;
  border-radius: 5px;
  font-size: 18px;
  color: ${props => props.textColor};
  border: 1px solid ${'#555555'};
  background-color: ${props => props.backgroundColor};
`

export const InfoMpText = styled.div<BasicProps>`
  ${flex.h.allCenter};
  width: 65%;
  margin: 0px 20px 0px 10px;
  border-radius: 5px;
  font-size: 18px;
  color: ${props => props.textColor};
  border: 1px solid ${'#555555'};
  background-color: ${props => props.backgroundColor};
`
